// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!

$body-bg: #050a0e;

$primary: #337ab7 !default;
$secondary: #869AB8 !default;
$primary-desat: #6C8AEC !default; // NEW
$success: #93D621 !default;
$info: #1E7AEA !default;
$warning: #FD8A00 !default;
$danger: #D62121 !default;